<template>
  <v-menu v-model="open" offset-y>
    <template #activator="on">
      <v-text-field
          v-on="on"
          @click="open = true"
          label="Room Filter Preset"
          :value="selectedTitle"
          readonly
          dense
          hide-details
          outlined
          class="room-filter-preset"/>
    </template>
    <v-list dense>
      <v-list-item @click="select(null)">
        <v-list-item-content>
          <v-list-item-title>Current Site</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mx-2"/>

      <v-list-item
          v-for="o in options"
          :key="o.id"
          @click="select(o.filter)">
        <v-list-item-content>
          <v-list-item-title>{{ o.title }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action class="my-0">
          <v-btn icon @click.stop="deleteFilter(o.id)" :loading="deleting">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-subheader v-if="options.length === 0">No saved filters</v-subheader>

      <v-divider class="mx-2"/>
      <v-list-item :disabled="!hasActiveFilter || hasSelectedOption" @click.stop="saveCurrentView">
        <v-list-item-content>
          <v-list-item-title>Save current view...</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <v-dialog v-model="dialogOpen" max-width="400">
      <v-card>
        <v-card-title>Save Current View</v-card-title>
        <v-card-text>
          <v-text-field v-model="newFilter.title" label="Filter Name" placeholder="Custom View 1"/>
        </v-card-text>
        <v-card-actions>
          <v-spacer/>
          <v-btn @click="dialogOpen = false" text>Cancel</v-btn>
          <v-btn
              :disabled="!newFilter.title"
              color="accent"
              @click="save"
              :loading="saving">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex';
import {RoomFilterUtil} from '@/views/room-booking/calendar/room-filter-util';
import {isEqual} from 'lodash';

export default {
  name: 'RoomFilterPreset',
  data() {
    return {
      dialogOpen: false,
      open: false,
      selected: null,

      newFilter: {
        title: '',
        sites: [],
        resources: []
      },
      saving: false,
      deleting: false
    };
  },
  computed: {
    ...mapGetters('user', ['userProfile']),
    ...mapState('views/roomBooking/bookingCalendar', ['filterSelection']),
    /** @type {kahu.firestore.RoomBookingCalendarFilters} */
    userFilters() {
      return (this.userProfile && this.userProfile.roomBookingCalendarFilters) || {};
    },
    options() {
      return Object.entries(this.userFilters).reduce((options, [id, filter]) => {
        options.push({
          id,
          title: filter.title,
          filter
        });
        return options;
      }, []);
    },
    hasActiveFilter() {
      return Boolean(this.filterSelection) &&
          (this.filterSelection.sites.length > 0 ||
              this.filterSelection.resources.length > 0);
    },

    selectedOption() {
      return this.options.find(o => o.id === this.selected);
    },
    hasSelectedOption() {
      return Boolean(this.selectedOption);
    },
    selectedTitle() {
      if (this.hasSelectedOption) {
        return this.selectedOption.title;
      }
      if (this.selected === 'current-site') {
        return 'Current Site';
      }
      return null;
    },

    nextKey() {
      const rfu = new RoomFilterUtil(this.userFilters);
      return rfu.nextKey;
    }
  },
  watch: {
    filterSelection: {
      deep: true,
      immediate: true,
      handler(s) {
        this.updateInternalValue();
      }
    }
  },
  methods: {
    ...mapActions('user', ['addUserFilter', 'deleteUserFilter']),
    select(filter) {
      this.$emit('selected', filter);
    },
    saveCurrentView() {
      if (!this.hasActiveFilter || this.selectedOption) {
        return;
      }
      this.newFilter = {
        ...this.filterSelection,
        title: null
      };
      this.dialogOpen = true;
    },
    async save() {
      this.saving = true;
      try {
        const key = this.nextKey;
        await this.addUserFilter({key, filter: this.newFilter});
        this.$notify.showSuccess(`Filter saved`);
        this.dialogOpen = false;
        this.open = false;
        this.selected = key;
      } catch (e) {
        this.$notify.showError(`Error saving filter, ${e.message}`);
        this.$logger.error('save', e);
      } finally {
        this.saving = false;
      }
    },
    async deleteFilter(id) {
      this.deleting = true;
      try {
        await this.deleteUserFilter(id);
        this.$notify.showSuccess(`Filter deleted`);
      } catch (e) {
        this.$notify.showError(`Error deleting filter, ${e.message}`);
        this.$logger.error('deleteFilter', e);
      } finally {
        this.deleting = false;
      }
    },

    updateInternalValue() {
      if (!this.filterSelection) {
        this.selected = 'current-site';
      } else {
        let option;
        if (this.filterSelection.title) {
          option = this.options.find(o => o.title === this.filterSelection.title);
        } else {
          option = this.options.find(o => isEqual(o.filter, this.filterSelection));
        }
        this.selected = option ? option.id : null;
      }
    }
  }
};
</script>

<style scoped>
.room-filter-preset {
  max-width: 200px;
}
</style>
