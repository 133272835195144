<template>
  <v-autocomplete
      :search-input.sync="searchText"
      :items="availableItems"
      item-text="title"
      :item-value="byRef"
      :value="selected"
      @input="selectedChanged"
      append-icon="$dropdown"
      placeholder="(No Location)"
      hide-details
      hide-no-data/>
</template>

<script>
import {byRef} from '@/store/firestore-util';
import {mapGetters, mapState} from 'vuex';
import {eventsConflictWithRange} from '@/util/range';

export default {
  name: 'CategoryField',
  props: {
    value: {
      type: Object,
      default: null
    },
    range: {
      type: Object,
      default: null
    },
    readonly: Boolean
  },
  data() {
    return {
      searchText: null,
      /** @type {string|null} */
      selected: null
    };
  },
  computed: {
    ...mapState('resources', ['resourcesBySiteId']),
    ...mapGetters('sites', ['activeSiteId']),
    ...mapGetters('views/roomBooking/bookingCalendar', ['events']),
    resources() {
      return Object.values(this.resourcesBySiteId[this.activeSiteId])
          .map(r => {
            return {
              title: r.title,
              ref: r.ref
            };
          });
    },
    items() {
      // make sure the value is always in the list of items
      const result = this.resources;
      if (this.value) {
        // noinspection JSCheckFunctionSignatures
        const valueRef = byRef(this.value);
        return [this.value].concat(result.filter(p => byRef(p) !== valueRef));
      }
      return result;
    },
    availableItems() {
      const available = this.items.filter(i => {
        return !eventsConflictWithRange(this.events, this.range, i.ref);
      });
      available.push(this.value);
      return available;
    },
    selectedLocation() {
      if (!this.selected) return this.selected;
      return this.availableItems.find(l => byRef(l) === this.selected) || null;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(n, o) {
        const ref = byRef(n);
        if (ref !== byRef(o)) {
          this.selected = ref;
        }
      }
    }
  },
  methods: {
    byRef,
    selectedChanged(selected) {
      const old = this.selected;
      if (selected !== old) {
        this.selected = selected;
        this.$emit('input', this.selectedLocation);
      }
    }
  }
};
</script>

<style scoped>

</style>
