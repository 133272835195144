<template>
  <v-card>
    <v-card-text class="pt-1">
      <v-textarea
          ref="noteField"
          v-model="internalNote"
          placeholder="e.g. Remember to setup the whiteboard"
          hide-details
          filled
          class="mt-2 notes-field"
          auto-grow
          rows="4"
          :readonly="readOnly"
          background-color="amber lighten-4"/>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BookingPopoutNotes',
  props: {
    note: {
      type: String,
      default: ''
    },
    readOnly: Boolean
  },
  computed: {
    internalNote: {
      get() {
        return this.note;
      },
      set(v) {
        this.$emit('update:note', v);
      }
    }
  }
};
</script>

<style scoped>

.notes-field >>> textarea {
  overflow: auto;
  max-height: 15em;
  margin-top: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

</style>
