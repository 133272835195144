import { render, staticRenderFns } from "./BookingPopoutTabs.vue?vue&type=template&id=c076dd54&scoped=true&"
import script from "./BookingPopoutTabs.vue?vue&type=script&lang=js&"
export * from "./BookingPopoutTabs.vue?vue&type=script&lang=js&"
import style0 from "./BookingPopoutTabs.vue?vue&type=style&index=0&id=c076dd54&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c076dd54",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VTab,VTabItem,VTabs,VTabsItems,VTabsSlider})
