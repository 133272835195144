<template>
  <v-menu offset-y>
    <template #activator="{on, attrs}">
      <v-btn v-on="on" v-bind="attrs" icon>
        <v-icon>mdi-help</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-list>
        <v-list-item v-for="(slot, i) in slotTypes" :key="i">
          <v-list-item-avatar>
            <div class="avatar white--text" v-bind="slot.bind"/>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>{{ slot.title }}</v-list-item-title>
            <v-list-item-subtitle>{{ slot.subtitle }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'HelpBtn',
  props: {
    m365SyncEnabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    slotTypes() {
      const res = [];

      res.push({
        title: 'Reserved',
        subtitle: 'The space is reserved for the duration',
        bind: {
          class: 'primary primary-text--text'
        }
      });

      res.push({
        title: 'Checked in',
        subtitle: 'The reservation has been checked in',
        bind: {
          class: 'accent'
        }
      });
      res.push({
        title: 'Checked out',
        subtitle: 'The reservation has been checked out',
        bind: {
          class: 'primary checked-out'
        }
      });

      res.push({
        title: 'Setup or clean down',
        subtitle: 'Reserved time to setup or clean down the space',
        bind: {
          class: 'setup'
        }
      });

      res.push({
        title: 'Requires approval',
        subtitle: 'The space requires manual approval to proceed with the reservation',
        bind: {
          class: 'secondary'
        }
      });
      if (this.m365SyncEnabled) {
        res.push({
          title: 'Not accepted',
          subtitle: 'The M365 resource has not accepted this reservation',
          bind: {
            class: 'secondary striped'
          }
        });
      }
      res.push({
        title: 'Blocked',
        subtitle: 'A reservation exists for an adjoining space',
        bind: {
          class: 'striped'
        }
      });
      res.push({
        title: 'Rejected',
        subtitle: 'The reservation has been rejected',
        bind: {
          class: 'error striped'
        }
      });
      return res;
    }
  }
};
</script>

<style scoped>
.avatar {
  width: calc(100% - 2px);
  height: calc(100% - 2px);
  border: 1px solid !important;
  border-radius: 50%;
}

.avatar.checked-out {
  opacity: 0.8;
  border-style: dashed !important;
}

.avatar.setup {
  position: relative;
  overflow: hidden;
  --divide: 12px;
}

.avatar.setup::before,
.avatar.setup::after {
  content: '';
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;

  background-color: var(--v-primary-base);
  border: 1px solid !important;
}

.avatar.setup::before {
  bottom: auto;
  height: calc(var(--divide) + 1px);
  opacity: 0.3;
}

.avatar.setup::after {
  top: var(--divide);
}
</style>
