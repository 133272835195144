var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var toolTipOn = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},Object.assign({}, on, toolTipOn)),[_c('v-icon',[_vm._v("mdi-filter-variant")])],1)]}}],null,true)},[_vm._v(" Choose rooms and other filters ")])]}}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('v-card',{attrs:{"width":"256"}},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{attrs:{"dense":""}},[(!_vm.loaded)?_c('div',{staticClass:"loading"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"accent"}})],1):[_c('v-list-item',{attrs:{"two-line":""}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Show rejected bookings")]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.hiddenEventsCount)+" bookings hidden")])],1),_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.internalShowRejectedEvents),callback:function ($$v) {_vm.internalShowRejectedEvents=$$v},expression:"internalShowRejectedEvents"}})],1)],1),_c('v-subheader',[_vm._v("Rooms to Show")]),_c('v-list-item-group',{staticClass:"selection-list",attrs:{"multiple":""},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}},[_vm._l((_vm.resourcesBySiteId),function(resources,siteId){return [_c('v-list-item',{key:siteId,attrs:{"value":siteId},on:{"click":function($event){return _vm.checkSite(siteId)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{staticClass:"font-weight-bold",domProps:{"textContent":_vm._s(_vm.sitesById[siteId].title)}})],1),_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-checkbox',{attrs:{"input-value":active,"color":"accent darken-1"}})],1)]}}],null,true)}),_vm._l((resources),function(r){return _c('v-list-item',{key:r.id,attrs:{"value":r.id},on:{"click":function($event){return _vm.checkResource(siteId)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-content',{staticClass:"pa-0"},[_c('v-list-item-title',{domProps:{"textContent":_vm._s(r.title)}})],1),_c('v-list-item-action',{staticClass:"ma-0"},[_c('v-checkbox',{attrs:{"disabled":_vm.selected.includes(siteId),"input-value":active || _vm.selected.includes(siteId),"color":"accent darken-1"}})],1)]}}],null,true)})}),_c('v-divider',{key:siteId + 'divider',staticClass:"mx-2"})]})],2)]],2)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"accent","depressed":"","disabled":_vm.selected.length === 0},on:{"click":_vm.done}},[_vm._v("OK")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }