<template>
  <v-card max-width="400">
    <v-card-text>
      <v-icon left>mdi-clock</v-icon>
      <v-text-field
          class="d-inline-block field-name mr-1"
          label="Setup"
          suffix="mins"
          outlined
          hide-details
          placeholder="0"
          dense
          v-model.number="internalSetupMins"
          :readonly="readOnly"
          type="number"
          step="5"
          min="0"/>
      <v-text-field
          class="d-inline-block field-name mx-1"
          label="Clean down"
          suffix="mins"
          outlined
          hide-details
          placeholder="0"
          dense
          v-model.number="internalCleanDownMins"
          :readonly="readOnly"
          type="number"
          step="5"
          min="0"/>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn icon @click="removeSetupTimes" v-on="on">
            <v-icon>mdi-delete</v-icon>
          </v-btn>
        </template>
        Remove setup and clean down time
      </v-tooltip>
      <!--        <dd class="align-self-stretch d-flex align-center field-name">-->
      <!--          <v-text-field-->
      <!--              label="Setup"-->
      <!--              suffix="mins"-->
      <!--              outlined-->
      <!--              hide-details-->
      <!--              placeholder="0"-->
      <!--              dense-->
      <!--              v-model.number="internalSetupMins"-->
      <!--              :readonly="readOnly"-->
      <!--              type="number"-->
      <!--              step="5"-->
      <!--              min="0"/>-->
      <!--        </dd>-->
      <!--        <dt class="field-name">-->
      <!--          <v-icon left>mdi-clock</v-icon>-->
      <!--        </dt>-->
      <!--        <dd class="align-self-stretch d-flex align-center field-name">-->
      <!--          <v-text-field-->
      <!--              label="Clean down"-->
      <!--              suffix="mins"-->
      <!--              outlined-->
      <!--              hide-details-->
      <!--              placeholder="0"-->
      <!--              dense-->
      <!--              v-model.number="internalCleanDownMins"-->
      <!--              :readonly="readOnly"-->
      <!--              type="number"-->
      <!--              step="5"-->
      <!--              min="0"/>-->
      <!--        </dd>-->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'BookingPopoutSetup',
  props: {
    setupMins: {
      type: Number,
      default: 0
    },
    cleanDownMins: {
      type: Number,
      default: 0
    },
    readOnly: Boolean,
    showSetupTimeOverride: Boolean
  },
  computed: {
    internalSetupMins: {
      get() {
        return this.setupMins;
      },
      set(v) {
        this.$emit('update:setupMins', v);
      }
    },
    internalCleanDownMins: {
      get() {
        return this.cleanDownMins;
      },
      set(v) {
        this.$emit('update:cleanDownMins', v);
      }
    },
    internalSetupTimeOverride: {
      get() {
        return this.showSetupTimeOverride;
      },
      set(v) {
        this.$emit('update:showSetupTimeOverride', v);
      }
    }
  },
  methods: {
    removeSetupTimes() {
      this.internalSetupMins = 0;
      this.internalCleanDownMins = 0;
      this.internalSetupTimeOverride = null;
    }
  }
};
</script>

<style scoped>

.info-table {
  display: grid;
  grid-gap: 8px 16px;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto 1fr;
  font-size: 16px;
}

.info-table dt {
  grid-column-start: 1;
}

.info-table dd {
  grid-column-start: 2;
}

.field-name {
  max-width: 130px;
  font-size: 16px;
}

.field-name span:first-child {
  padding-left: 32px;
}

.remove {
  right: 0;
}

</style>
