<template>
  <v-dialog persistent v-model="dialogOpen" width="450px">
    <v-card class="ma-auto">
      <v-toolbar flat color="warning">
        <v-icon size="28" color="primary-text">mdi-alert-circle</v-icon>
        <span class="mx-2 text-h5 primary-text--text">Catering not available</span>
      </v-toolbar>
      <v-card-text class="my-2">
        <slot>
          <span>Please Confirm your action</span>
        </slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
            text
            @click="cancel">
          {{ declineBtnText }}
        </v-btn>
        <v-btn
            depressed
            color="warning"
            @click="confirm">
          {{ confirmBtnText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ConfirmationPrompt',
  props: {
    confirmBtnText: {
      type: String,
      default: 'Confirm'
    },
    declineBtnText: {
      type: String,
      default: 'Decline'
    }
  },
  data() {
    return {
      message: 'Please Confirm your action',
      acceptCallback: () => {},
      rejectCallback: () => {},
      dialogOpen: false
    };
  },
  methods: {
    showConfirm(acceptCallback, rejectCallback) {
      this.acceptCallback = acceptCallback;
      this.rejectCallback = rejectCallback;
      this.dialogOpen = true;
    },
    confirm() {
      this.acceptCallback();
      this.close();
    },
    cancel() {
      this.rejectCallback();
      this.close();
    },
    close() {
      this.dialogOpen = false;
      this.acceptCallback = () => {};
      this.rejectCallback = () => {};
      this.message = '';
    }
  }
};
</script>

<style scoped/>
