import {incrementSuffix} from '@/util/suffix';
const numericCollator = new Intl.Collator('en', {numeric: true});

export class RoomFilterUtil {
  /**
   * @param {kahu.firestore.RoomBookingCalendarFilters} filters
   */
  constructor(filters) {
    this.filters = filters;
  }

  /**
   * @return {string[]}
   */
  get keys() {
    return Object.keys(this.filters || {});
  }

  /**
   * Get the last key in the sorted list of keys
   *
   * Uses Intl.Collator to sort numbers as you'd expect: [r10, r6, r8] => [r6, r8, r10]
   *
   * @return {string|undefined}
   */
  get lastKey() {
    const sorted = this.keys.sort(numericCollator.compare);
    if (sorted.length === 0) {
      return undefined;
    }
    return sorted[sorted.length - 1];
  }

  /**
   * @return {string}
   */
  get nextKey() {
    const last = this.lastKey;
    return incrementSuffix(last, 'f1');
  }
}
