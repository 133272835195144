<template>
  <div class="busy-bar">
    <div v-for="({bind, range}, index) in parsedRanges" :key="index" class="range" v-bind="bind">
      <slot name="range" :range="range" :index="index"/>
    </div>
    <div v-for="(item, index) in conflicts" :key="'c:' + index" class="conflict range" v-bind="item.bind">
      <slot name="conflict" :conflict="item.conflict" :index="index"/>
    </div>
  </div>
</template>

<script>
import {computeConflicts} from '@/util/range';
import {isCssColor} from 'vuetify/lib/util/colorUtils';

export default {
  name: 'BusyBar',
  props: {
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
    rangeColor: {
      type: [Function, String],
      default: 'primary'
    },
    ranges: {
      type: Array,
      default: null
    },
    dark: Boolean
  },
  computed: {
    parsedRanges() {
      const ranges = this.ranges || [];
      return ranges.map(range => this.parseRange(range)).filter(v => Boolean(v));
    },
    conflicts() {
      return computeConflicts(this.parsedRanges.map(r => r.range))
          .map(c => this.parseConflict(c))
          .filter(v => Boolean(v));
    }
  },
  methods: {
    parseRange(range) {
      const position = this.calcRangeBounds(range);
      if (!position) return null;
      const bind = {};
      bind.style = {
        left: `${position.left.toFixed(2)}%`,
        width: `${(position.right - position.left).toFixed(2)}%`
      };

      // apply colour just like vuetify does, so you can use #fff or red or primary
      const color = this.calcRangeColor(range);
      if (isCssColor(color)) {
        bind.style.backgroundColor = color;
      } else if (color) {
        bind.class = {
          [color]: true
        };
      }

      if (this.dark) {
        if (!bind.class) bind.class = {};
        bind.class['theme--dark'] = true;
      }

      return {...position, color, range, bind};
    },
    parseConflict(conflict) {
      const position = this.calcRangeBounds(conflict);
      if (!position) return null;
      const bind = {};
      bind.style = {
        left: `${position.left.toFixed(2)}%`,
        width: `${(position.right - position.left).toFixed(2)}%`
      };
      bind.class = {
        'error': true
      };
      return {...position, bind, conflict};
    },
    calcRangeColor(range) {
      if (typeof this.rangeColor === 'string') return this.rangeColor;
      if (typeof this.rangeColor === 'function') return this.rangeColor(range);
      return undefined;
    },
    calcRangeBounds(range) {
      const span = this.max - this.min;
      const left = Math.min(100, (range.start - this.min) / span * 100);
      const right = Math.max(0, (range.end - this.min) / span * 100);
      if (left >= 100) return null;
      if (right <= 0) return null;
      return {left, right};
    },
    rangesOverlap(a, b) {
      // aaaa      aaaa  aaaa    aa
      //   bbbb  bbbb     bb    bbbb
      const start = Math.max(a.start, b.start);
      const end = Math.min(a.end, b.end);
      if (end <= start) return null;
      return {start, end};
    }
  }
};
</script>

<style scoped>
.busy-bar {
  position: relative;
  height: 8px;
}

.range {
  position: absolute;
  top: 0;
  height: 100%;
}
</style>
