<template>
  <v-menu offset-y :close-on-content-click="false" v-model="open">
    <template #activator="{ on }">
      <v-tooltip bottom>
        <template #activator="{on: toolTipOn}">
          <v-btn icon v-on="{...on, ...toolTipOn}">
            <v-icon>mdi-filter-variant</v-icon>
          </v-btn>
        </template>
        Choose rooms and other filters
      </v-tooltip>
    </template>

    <v-card width="256">
      <v-card-text class="pa-0">
        <v-list dense>
          <div v-if="!loaded" class="loading">
            <v-progress-circular indeterminate color="accent"/>
          </div>
          <template v-else>
            <v-list-item two-line>
              <v-list-item-content>
                <v-list-item-title>Show rejected bookings</v-list-item-title>
                <v-list-item-subtitle>{{ hiddenEventsCount }} bookings hidden</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-switch v-model="internalShowRejectedEvents"/>
              </v-list-item-action>
            </v-list-item>
            <v-subheader>Rooms to Show</v-subheader>
            <v-list-item-group v-model="selected" multiple class="selection-list">
              <template v-for="(resources, siteId) in resourcesBySiteId">
                <v-list-item :key="siteId" :value="siteId" @click="checkSite(siteId)">
                  <template #default="{ active }">
                    <v-list-item-content class="pa-0">
                      <v-list-item-title v-text="sitesById[siteId].title" class="font-weight-bold"/>
                    </v-list-item-content>

                    <v-list-item-action class="ma-0">
                      <v-checkbox :input-value="active" color="accent darken-1"/>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-list-item v-for="r in resources" :key="r.id" :value="r.id" @click="checkResource(siteId)">
                  <template #default="{ active }">
                    <v-list-item-content class="pa-0">
                      <v-list-item-title v-text="r.title"/>
                    </v-list-item-content>

                    <v-list-item-action class="ma-0">
                      <v-checkbox
                          :disabled="selected.includes(siteId)"
                          :input-value="active || selected.includes(siteId)"
                          color="accent darken-1"/>
                    </v-list-item-action>
                  </template>
                </v-list-item>
                <v-divider :key="siteId + 'divider'" class="mx-2"/>
              </template>
            </v-list-item-group>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn text @click="close">Cancel</v-btn>
        <v-btn @click="done" color="accent" depressed :disabled="selected.length === 0">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-menu>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';

export default {
  name: 'RoomFilter',
  data() {
    return {
      open: false,
      selected: []
    };
  },
  computed: {
    ...mapState('views/roomBooking/bookingCalendar', ['filterSelection', 'showRejectedEvents']),
    ...mapGetters('views/roomBooking/bookingCalendar', ['hiddenEventsCount']),
    ...mapState('resources', ['resourcesBySiteId']),
    ...mapGetters('resources', ['loaded']),
    ...mapGetters('sites', ['sitesById', 'activeSiteId']),
    resourceIdsBySiteId() {
      const bySideId = {};
      for (const [siteId, resources] of Object.entries(this.resourcesBySiteId)) {
        bySideId[siteId] = resources.map(r => r.id);
      }
      return bySideId;
    },
    internalShowRejectedEvents: {
      get() {
        return this.showRejectedEvents;
      },
      set(v) {
        this.setShowRejectedEvents(v);
      }
    }
  },
  watch: {
    filterSelection: {
      deep: true,
      immediate: true,
      handler(s) {
        this.updateInternalValue();
      }
    },
    activeSiteId: {
      immediate: true,
      handler(s) {
        this.updateInternalValue();
      }
    }
  },
  mounted() {
    this.load();
    if (!this.filterSelection) {
      this.selected = [this.activeSiteId];
    } else {
      this.selected = [...this.filterSelection.sites, ...this.filterSelection.resources];
    }
  },
  methods: {
    ...mapActions('resources', ['fetchResourcesForAllSites']),
    ...mapMutations('views/roomBooking/bookingCalendar', ['setShowRejectedEvents']),
    checkSite(siteId) {
      this.$nextTick(() => {
        if (this.selected.includes(siteId)) {
          // can we remove any child resources to simplify the list?
          const resourceIds = this.resourceIdsBySiteId[siteId];
          this.selected = this.selected.filter(id => !resourceIds.includes(id));
        }
      });
    },
    checkResource(siteId) {
      this.$nextTick(() => {
        // can we remove any child resources to simplify the list?
        const resourceIds = this.resourceIdsBySiteId[siteId];
        const allForSite = resourceIds.every(id => this.selected.includes(id));
        if (allForSite) {
          this.selected = [
            ...this.selected.filter(id => !resourceIds.includes(id)),
            siteId
          ];
        }
      });
    },
    close() {
      this.open = false;
      this.updateInternalValue();
    },
    done() {
      const selection = {sites: [], resources: []};
      for (const id of this.selected) {
        if (this.sitesById.hasOwnProperty(id)) {
          selection.sites.push(id);
        } else {
          selection.resources.push(id);
        }
      }
      this.$emit('selected', selection);
      this.close();
    },
    load() {
      this.fetchResourcesForAllSites()
          .catch(err => this.$logger.error('load', err));
    },

    updateInternalValue() {
      if (this.open) {
        return;
      }
      if (this.filterSelection) {
        this.selected = [...this.filterSelection.sites, ...this.filterSelection.resources];
      } else {
        this.selected = [this.activeSiteId];
      }
    }
  }
};
</script>

<style scoped>
.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.selection-list {
  --list-item-height: 30px;
  max-height: calc(10 * var(--list-item-height));
  min-height: calc(5 * var(--list-item-height));
  padding-bottom: 4px;
  overflow: auto;
}

.v-list-item--dense, .v-list--dense .v-list-item {
  min-height: var(--list-item-height);
}
</style>
