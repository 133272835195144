<template>
  <v-card>
    <v-card-text>
      <dl class="info-table">
        <dt>
          <v-icon>mdi-map-marker</v-icon>
          <span>Location:</span>
        </dt>
        <dd>
          <category-field
              class="mt-0 pt-0"
              :range="range"
              v-model="internalCategory"/>
        </dd>
        <dd v-if="otherLocations > 0">
          (+{{ otherLocations }} more location{{ otherLocations > 1 ? 's' : '' }})
        </dd>
        <dt>
          <v-icon>mdi-account</v-icon>
          <span>Owner:</span>
        </dt>
        <dd>
          <owner-field-readonly
              v-if="readonly || internalOwner && !isAddedEvent"
              :value="internalOwner"/>
          <owner-field
              v-else
              class="mt-0 pt-0"
              v-model="internalOwner"/>
        </dd>
        <dt/>
        <dd>
          <v-checkbox
              label="Enable auto check in"
              hide-details
              class="mt-0"
              :readonly="readonly"
              v-model="internalEnableAutoCheckIn"/>
        </dd>
      </dl>
    </v-card-text>
  </v-card>
</template>

<script>
import OwnerField from '@/views/room-booking/calendar/OwnerField';
import OwnerFieldReadonly from '@/views/room-booking/calendar/OwnerFieldReadonly';
import CategoryField from '@/views/room-booking/calendar/CategoryField';
import {mapGetters} from 'vuex';

export default {
  name: 'BookingPopoutInfo',
  components: {OwnerFieldReadonly, OwnerField, CategoryField},
  props: {
    category: {
      type: String,
      default: ''
    },
    range: {
      type: Object,
      default: null
    },
    owner: {
      type: Object,
      default: null
    },
    reservationCount: {
      type: Number,
      default: 0
    },
    requiresCheckIn: {
      type: Boolean,
      default: true
    },
    added: Boolean,
    readonly: Boolean
  },
  computed: {
    ...mapGetters('views/roomBooking/bookingCalendar', ['resourceByTitle']),
    otherLocations() {
      return this.reservationCount - 1;
    },
    isAddedEvent() {
      return this.added;
    },
    internalOwner: {
      get() {
        return this.owner;
      },
      set(v) {
        this.$emit('update:owner', v);
      }
    },
    internalCategory: {
      get() {
        return this.resourceByTitle(this.category);
      },
      set(v) {
        this.$emit('update:category', v);
      }
    },
    internalEnableAutoCheckIn: {
      get() {
        return !this.requiresCheckIn;
      },
      set(v) {
        this.$emit('update:requiresCheckIn', !v);
      }
    }
  }
};
</script>

<style scoped>

.info-table {
  display: grid;
  grid-gap: 8px 16px;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto 1fr;
  font-size: 16px;
}

.info-table dt {
  grid-column-start: 1;
  display: grid;
  grid-template-columns: 32px 1fr;
  align-items: center;
  justify-items: start;
  min-height: 32px;
}

.info-table dd {
  grid-column-start: 2;
}
</style>
