<template>
  <div class="w-100 d-inline-flex">
    <div class="flex">
      <v-tabs grow show-arrows center-active ref="tabs" class="tabs" v-model="selectedTab">
        <v-tab v-for="t in tabs" :href="'#' + t" :key="t">{{ t }}</v-tab>
        <v-tabs-slider color="primary"/>
      </v-tabs>

      <v-tabs-items v-model="selectedTab">
        <v-tab-item value="Info">
          <booking-popout-info
              :readonly="readOnly"
              :owner.sync="internalOwner"
              :range="range"
              :added="added"
              :requires-check-in.sync="internalRequiresCheckIn"
              :category.sync="internalCategory"
              :reservation-count="reservationCount"/>
        </v-tab-item>
        <v-tab-item value="Note">
          <booking-popout-notes
              :note.sync="internalNote"
              :read-only="readOnly"/>
        </v-tab-item>
        <v-tab-item value="Setup">
          <booking-popout-setup
              :clean-down-mins.sync="internalCleanDownMins"
              :setup-mins.sync="internalSetupMins"
              :show-setup-time-override.sync="internalSetupTimeOverride"
              :read-only="readOnly"/>
        </v-tab-item>
        <v-tab-item value="Catering">
          <v-card>
            <v-card-text class="pt-1">
              <catering-description
                  class="mt-2"
                  :catering-is-enabled="cateringIsEnabled"
                  :catering-can-be-edited="cateringCanBeEdited"
                  :catering-request.sync="internalCateringRequest"
                  :read-only="readOnly"/>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import BookingPopoutInfo from '@/views/room-booking/calendar/BookingPopoutInfo';
import BookingPopoutSetup from '@/views/room-booking/calendar/BookingPopoutSetup';
import BookingPopoutNotes from '@/views/room-booking/calendar/BookingPopoutNotes';
import CateringDescription from '@/views/room-booking/calendar/components/CateringDescription';
import {mapGetters} from 'vuex';

export default {
  name: 'BookingPopoutTabs',
  components: {CateringDescription, BookingPopoutNotes, BookingPopoutSetup, BookingPopoutInfo},
  props: {
    event: {
      type: Object,
      default: null
    },
    range: {
      type: Object,
      default: null
    },
    owner: {
      type: Object,
      default: null
    },
    category: {
      type: String,
      default: ''
    },
    reservationCount: {
      type: Number,
      default: 0
    },
    added: {
      type: Boolean,
      default: false
    },
    setupMins: {
      type: Number,
      default: 0
    },
    cleanDownMins: {
      type: Number,
      default: 0
    },
    cateringIsEnabled: {
      type: Boolean,
      default: false
    },
    cateringCanBeEdited: {
      type: Boolean,
      default: true
    },
    note: {
      type: String,
      default: ''
    },
    requiresCheckIn: {
      type: Boolean,
      default: true
    },
    cateringRequest: {
      type: Object,
      default: null
    },
    readOnly: Boolean,
    open: Boolean,
    hasSetup: Boolean,
    showNoteOverride: Boolean,
    showCateringOverride: Boolean,
    showSetupTimeOverride: Boolean,
    value: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      tab: null
    };
  },
  computed: {
    ...mapGetters('views/roomBooking/bookingCalendar/workOrders', ['workOrderByRef', 'workOrdersByCategory']),
    tabs() {
      const tabs = ['Info'];
      if (this.hasSetup) tabs.push('Setup');
      if (this.showNote) tabs.push('Note');
      if (this.showCatering) tabs.push('Catering');
      return tabs;
    },
    selectedTab: {
      get() {
        if (this.value) {
          return this.value;
        }
        if (this.tab) {
          return this.tab;
        }
        return null;
      },
      set(t) {
        this.tab = t;
        this.$emit('input', t);
      }
    },
    showNote() {
      return this.showNoteOverride && !this.readOnly || Boolean(this.note);
    },
    showCatering() {
      return this.showCateringOverride && !this.readOnly || this.internalCateringRequest !== null;
    },
    internalRange() {
      return {start: this.start, end: this.end};
    },
    internalOwner: {
      get() {
        return this.owner;
      },
      set(v) {
        this.$emit('update:owner', v);
      }
    },
    internalCategory: {
      get() {
        return this.category;
      },
      set(v) {
        this.$emit('update:category', v);
      }
    },
    internalSetupMins: {
      get() {
        return this.setupMins;
      },
      set(v) {
        this.$emit('update:setupMins', v);
      }
    },
    internalCleanDownMins: {
      get() {
        return this.cleanDownMins;
      },
      set(v) {
        this.$emit('update:cleanDownMins', v);
      }
    },
    internalNote: {
      get() {
        return this.note;
      },
      set(v) {
        this.$emit('update:note', v);
      }
    },
    internalCateringRequest: {
      get() {
        return this.cateringRequest;
      },
      set(v) {
        this.$emit('update:catering-request', v);
      }
    },
    internalSetupTimeOverride: {
      get() {
        return this.showSetupTimeOverride;
      },
      set(v) {
        this.$emit('update:showSetupTimeOverride', v);
      }
    },
    internalRequiresCheckIn: {
      get() {
        return this.requiresCheckIn;
      },
      set(v) {
        this.$emit('update:requiresCheckIn', v);
      }
    }
  }
};
</script>

<style scoped>
.tabs {
  min-width: 400px;
  max-width: 400px;
}

.tabs >>> .v-tabs-bar {
  height: 32px;
}

.tabs >>> .v-tab {
  width: 90px;
}
</style>
