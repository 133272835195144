<template>
  <div class="booking-calendar" ref="bookingCalendar" :style="rootStyle">
    <v-toolbar :height="toolbarHeight">
      <day-chooser v-model="day"/>
      <v-spacer/>
      <v-toolbar-title class="mr-4">
        <span v-if="isAdmin" class="subtitle-1">
          Room count: {{ roomCount }}
        </span>
      </v-toolbar-title>
      <room-filter-preset @selected="setRoomFilter"/>
      <room-filter @selected="setRoomFilter"/>
      <help-btn :m365-sync-enabled="anyResourcesHaveM365SyncEnabled"/>
    </v-toolbar>
    <v-progress-linear indeterminate color="accent" v-if="!loaded" class="loading"/>
    <router-view ref="nestedView"/>
    <confirmation-prompt
        ref="confirmationPrompt"
        decline-btn-text="DON'T MOVE"
        confirm-btn-text="REMOVE CATERING">
      <span>
        Catering requests are not available in <strong>{{ dragEvent && dragEvent.category }}</strong>,
        moving <strong>"{{ dragEvent && dragEvent.name }}"</strong> to this room will remove the catering requests
        associated with this booking.
      </span>
    </confirmation-prompt>
    <v-calendar
        ref="calendar"
        type="category"
        v-model="day"
        @change="setTimeRange"
        @mousedown:event="onEventMouseDown"
        @mousedown.native.capture="currentEvent = $event"
        @mousedown:time-category="onTimeMouseDown"
        @mousemove.native.capture="currentEvent = $event"
        @mousemove:time-category="onTimeMouseMove"
        @mouseup:time-category="onTimeMouseUp"
        @mouseup.native.capture="currentEvent = $event"
        @mouseleave.native="onMouseLeave"
        :categories="categories"
        :events="eventsToShow"
        :event-color="eventColor"
        category-show-all
        interval-minutes="15"
        :interval-count="24 * 4 + 1"
        interval-height="20"
        interval-width="60"
        :event-height="0"
        hide-header
        :show-interval-label="showIntervalLabel"
        :style="{minWidth: (60 + categories.length * 48) + 'px'}">
      <template #day-body="{category}">
        <!--
      This span works around a bug in v-calendar+vue where the scoped style data-{hash} attr isn't added to the first
      child of the day-body slot. With this span here, it might not get the data-{hash} attr, but the others will.
      -->
        <span/>

        <div class="category">
          <div
              v-if="categoriesInMultipleSites"
              class="site"
              :class="{'first-resource-for-site': isFirstResourceForSite(category)}"
              :style="{'--var-site-resource-count': resourceCountForSite(category)}">
            <span>{{ siteTitleByResourceTitle(category) }}</span>
          </div>
          <div class="rotate" :class="{'with-site': categoriesInMultipleSites}">{{ category }}</div>
        </div>

        <div class="mask before-start" :style="maskBeforeStyles"/>
        <div class="mask after-end" :style="maskAfterStyles"/>

        <div
            v-if="today"
            class="current-time"
            :class="{first: category === categories[0]}"
            :style="{top: nowY + 'px'}"/>
      </template>
      <template #event="{event, singline}">
        <div class="event-details px-1" :class="{'py-1': !singline}">
          <div class="owner" v-if="event.owner">{{ event.owner.title }}</div>
        </div>
        <div v-if="event.rejected" class="event-icon-list">
          <v-icon class="event-icon" color="black">mdi-cancel</v-icon>
        </div>
        <!-- 2700000 is 45 minutes in milliseconds -->
        <div v-else-if="event.duration >= 2700000" class="event-icon-list">
          <v-icon v-if="event.reservationCount > 1" class="event-icon">
            mdi-link
          </v-icon>
          <v-icon
              v-if="event.cateringRequests && event.cateringRequests.length > 0"
              class="event-icon">
            mdi-coffee
          </v-icon>
          <v-icon
              v-if="event.note"
              class="event-icon">
            mdi-message-text
          </v-icon>
        </div>
        <!--
      These handles have to be after the owner details or sometimes we hit a bug where the event path is incorrect
      during event click causing the div.owner to be a child of div.handle.top, but not at the same time.
      This results in the popout not showing in the correct place on the calendar and things getting strange.
      -->
        <div v-if="dragEditable(event)" @mousedown.stop="onHandleMouseDown('end', event)" class="handle top"/>
        <div v-if="dragEditable(event)" @mousedown.stop="onHandleMouseDown('start', event)" class="handle bottom"/>
      </template>
    </v-calendar>

    <v-menu
        ref="selectedMenu"
        v-model="selectedOpen"
        :activator="selectedElement"
        :close-on-click="false"
        :close-on-content-click="false"
        :offset-overflow="true"
        :open-on-click="false"
        disable-keys
        nudge-left="-8"
        nudge-top="-8">
      <booking-popout
          v-if="selectedEvent"
          min-width="360px"
          v-click-outside="maybeCloseMenu"
          :event="selectedEvent"
          :name.sync="selectedEvent.name"
          :setup-mins.sync="selectedEvent.setupMins"
          :clean-down-mins.sync="selectedEvent.cleanDownMins"
          :owner.sync="selectedEvent.owner"
          :start.sync="selectedEvent.start"
          :end.sync="selectedEvent.end"
          :note.sync="selectedEvent.note"
          :requires-check-in.sync="selectedEvent.requiresCheckIn"
          :approval.sync="selectedEvent.approval"
          :read-only="Boolean(selectedEvent.blocked || selectedEvent.privacy || selectedEvent.checkedOut
            || sectionRoomBookingReadOnly)"
          :catering-request.sync="selectedEvent.firstCateringWorkOrder"
          :work-order-refs="selectedEvent.workOrderRefs"
          :catering-edit-time-end="selectedEvent.cateringEditTimeEnd"
          :catering-is-enabled="selectedEvent.cateringIsEnabled"
          :committing="selectedCommitting"
          @close="closeSelected"
          @click:delete="deleteSelected"
          @click:commit="commitSelected"
          @click:reset="resetSelected"/>
    </v-menu>
  </div>
</template>

<script>
import * as dates from '@/util/dates';
import {currentDate, seconds, toYearMonthDay} from '@/util/dates';
import BookingPopout from '@/views/room-booking/calendar/BookingPopout';
import DayChooser from '@/views/room-booking/calendar/DayChooser';
import HelpBtn from '@/views/room-booking/calendar/HelpBtn';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import eventColor from './event-color';
import {hoursMinutes} from '@/util/times';
import RoomFilter from '@/views/room-booking/calendar/RoomFilter';
import RoomFilterPreset from '@/views/room-booking/calendar/RoomFilterPreset';
import ConfirmationPrompt from '@/views/room-booking/calendar/ConfirmationPrompt';

export default {
  name: 'BookingCalendar',
  components: {ConfirmationPrompt, RoomFilterPreset, RoomFilter, HelpBtn, DayChooser, BookingPopout},
  mixins: [eventColor],
  data() {
    return {
      // are we mounded / do we have refs ready
      ready: false,

      toolbarHeight: 56,
      nestedViewWidth: 0,

      day: currentDate(),

      /**
       * The event that is being dragged
       *
       * @type {kahu.firestore.ReservationRef}
       */
      dragEventKey: null,
      /**
       * The event that has just been created, or is having it's start/end dragged
       *
       * @type {kahu.firestore.ReservationRef}
       */
      createEventKey: null,
      // the offset within the dragEvent attached to the mouse during dragging
      dragTime: null,
      // when changing the duration of an event, this is the time that shouldn't change.
      anchorTime: null,

      // used during create when a mouse up both creates and closes menus, which we just opened
      ignoreNextMenuClose: false,
      // count how many times the user has attempted to reset the open booking.
      // This is used as a mechanism to avoid accidental resets
      // when using less obvious means like Esc or click outside.
      resetAttemptCount: 0,
      // how many times should a reset be attempted before we allow it
      resetAttemptAllowCount: 1,
      // how long after the last resetAttempt should we wait before resetting the timer to 0
      resetAttemptTimeout: 10 * seconds,
      // tracks timeout handler for resetting the attempt count after a period
      resetAttemptTimeoutHandle: 0,

      /**
       * The ref for the event that is being dragged
       *
       * @type {kahu.firestore.ReservationRef}
       */
      selectedEventKey: null,
      // the dom element that the details should be attached to
      selectedElement: null,
      // is the details window open
      selectedOpen: false,
      selectedCommitting: false,

      // handle used to update the calendar time
      minuteTicker: 0,
      currentEvent: null,
      today: true
    };
  },
  computed: {
    ...mapGetters('sites/active', ['workingHoursStartTime', 'workingHoursEndTime']),
    ...mapGetters('views/roomBooking/bookingCalendar', [
      'anyResourcesHaveM365SyncEnabled',
      'resources',
      'resourcesBySiteTitle',
      'siteTitleByResourceTitle',
      'isFirstResourceForSite',
      'resourceCountForSite',
      'eventsToShow',
      'eventByKey',
      'loaded',
      'roomCount'
    ]),
    ...mapGetters('appConfig', ['sectionRoomBookingReadOnly']),
    ...mapGetters('auth', ['isAdmin']),
    editable() {
      return !this.selectedOpen;
    },
    categories() {
      return this.resources.map(r => r.title);
    },
    categoriesInMultipleSites() {
      return Object.keys(this.resourcesBySiteTitle).length > 1;
    },
    /**
     * @return {CalendarEvent}
     */
    selectedEvent() {
      return this.eventByKey(this.selectedEventKey);
    },
    dragEvent() {
      return this.eventByKey(this.dragEventKey);
    },
    createEvent() {
      return this.eventByKey(this.createEventKey);
    },
    startTime() {
      return hoursMinutes(this.workingHoursStartTime);
    },
    endTime() {
      return hoursMinutes(this.workingHoursEndTime);
    },
    maskBeforeStyles() {
      if (!this.ready) return {};
      return {
        height: this.$refs.calendar.timeToY(this.startTime).toFixed(2) + 'px'
      };
    },
    maskAfterStyles() {
      if (!this.ready) return {};
      return {
        top: this.$refs.calendar.timeToY(this.endTime).toFixed(2) + 'px'
      };
    },
    nowY() {
      return this.ready ? this.$refs.calendar.timeToY(this.$refs.calendar.times.now).toFixed(2) : '-10';
    },

    rootStyle() {
      return {
        '--toolbar-height': this.toolbarHeight + 'px',
        '--nested-view-width': this.nestedViewWidth + 'px'
      };
    },
    isBookingView() {
      return this.$route.name === 'room-booking-calendar-view';
    }
  },
  watch: {
    today: {
      handler(v) {
        if (v) {
          this.scrollToNow();
        }
      }
    },
    day: {
      handler(v) {
        this.updateToday(v);
      }
    },
    isBookingView: {
      immediate: true,
      handler(v) {
        if (v) {
          this.$nextTick(() => {
            this.nestedViewWidth = this.$refs.nestedView.$el.clientWidth;
          });
        } else {
          this.nestedViewWidth = 0;
        }
      }
    },
    categoriesInMultipleSites: {
      immediate: true,
      handler(v) {
        if (!v) {
          return;
        }
        this.$nextTick(() => this.updateSiteLabelColors());
      }
    },
    categories: {
      immediate: true,
      handler(v) {
        if (!this.categoriesInMultipleSites) {
          return;
        }
        this.$nextTick(() => this.updateSiteLabelColors());
      }
    },
    selectedEvent: {
      immediate: true,
      handler(v) {
        if (!this.selectedOpen) {
          this.clearWorkOrderStore();
        }
      }
    }
  },
  mounted() {
    this.bind();
    this.ready = true;
    clearInterval(this.minuteTicker);
    this.minuteTicker = setInterval(() => {
      this.$refs.calendar.updateTimes();
      this.updateToday(this.day);
    }, dates.minutes);
    this.scrollToNow();

    document.addEventListener('keydown', this.onDocumentKeyDown.bind(this), {capture: true});
  },
  beforeDestroy() {
    clearInterval(this.minuteTicker);
    document.removeEventListener('keydown', this.onDocumentKeyDown);
  },
  methods: {
    ...mapMutations('views/roomBooking/bookingCalendar', ['setTimeRange', 'setFilterSelection']),
    ...mapMutations('views/roomBooking/bookingCalendar/workOrders', ['recordEdit', 'addWorkOrder']),
    ...mapActions('views/roomBooking/bookingCalendar', ['createNewBooking', 'bind']),
    ...mapActions('views/roomBooking/bookingCalendar/workOrders', {
      clearWorkOrderStore: 'clear',
      deleteWorkOrder: 'deleteWorkOrder'
    }),
    showIntervalLabel(date) {
      return date.time.endsWith(':00');
    },
    scrollToNow() {
      const maxScrollTo = this.$refs.bookingCalendar.scrollHeight - window.innerHeight;
      let scrollTo = Math.round(this.nowY) - (window.innerHeight * 0.2);
      if (scrollTo > maxScrollTo) {
        scrollTo = maxScrollTo;
      }

      this.$refs.bookingCalendar.scrollTo({top: scrollTo, behavior: 'smooth'});
    },
    updateToday(v) {
      const t = toYearMonthDay(v);
      this.today = t === this.$refs.calendar.times.now.date;
    },
    // selection support
    // NB: this isn't using the v-calendar @event:click as it doesn't play nicely with drags
    onEventClick({nativeEvent, event}) {
      const open = () => {
        this.selectedEventKey = event.key;
        this.selectedElement = nativeEvent.target.closest('.v-event-timed');
        this.$nextTick(() => {
          // next tick means the menu knows the activator has changed, force it capture dimensions
          // so it knows where to popup
          this.$refs.selectedMenu.updateDimensions();
          setTimeout(() => {
            this.ignoreNextMenuClose = false; // the menu successfully opened
            // sometimes we can clear selection between setting it up and opening the menu
            // that can cause the calendar to be non-editable and nothing to pop up
            if (this.selectedElement) {
              this.selectedOpen = true;
            }
          }, 10);
        });
      };

      if (this.selectedOpen) {
        if (this.maybeResetSelected(false)) {
          this.selectedOpen = false;
          setTimeout(open, 10);
        }
      } else {
        open();
      }

      nativeEvent.stopPropagation();
    },

    // drag/edit support
    onEventMouseDown({event, timed}) {
      if (this.currentEvent.button !== 0) return;
      if (event && timed) {
        this.dragEventKey = event.key;
      }
    },
    onTimeMouseDown(e) {
      if (this.sectionRoomBookingReadOnly) return;
      if (this.currentEvent.button !== 0) return;
      if (!this.editable) return;
      if (this.dragEvent && this.dragTime === null) {
        this.dragTime = this.toTime(e) - this.dragEvent.start;
      } else {
        this.anchorTime = this.roundTime(this.toTime(e));
        this.createNewBooking(
            {
              start: this.anchorTime,
              end: this.anchorTime + 15 * dates.minutes,
              category: e.category,
              approved: true
            }).then(event => this.createEventKey = event.key);
      }
    },
    onTimeMouseMove(e) {
      if (this.sectionRoomBookingReadOnly) return;
      if (!this.editable) return;

      // monitor if the user has the ctrl key held down
      const adjustment = this.currentEvent && this.currentEvent.ctrlKey ? 5 : 15;
      if (this.dragEvent && this.dragTime !== null) {
        if (!this.dragEditable(this.dragEvent)) return;
        // we are drag/moving an event around
        const {start, end} = this.dragEvent;
        const duration = end - start;
        const newStart = this.roundTime(this.toTime(e) - this.dragTime, adjustment);
        const newEnd = newStart + duration;

        if (this.dragEvent.start !== newStart ||
            this.dragEvent.end !== newEnd ||
            this.dragEvent.category !== e.category) {
          this.dragEvent.start = newStart;
          this.dragEvent.end = newEnd;
          this.dragEvent.category = e.category;
        }
      } else if (this.createEvent && this.anchorTime !== null) {
        const mouseTime = this.toTime(e);
        const roundedTime = this.roundTime(mouseTime, adjustment, mouseTime < this.anchorTime);
        const min = Math.min(roundedTime, this.anchorTime);
        const max = Math.max(roundedTime, this.anchorTime);

        if (this.createEvent.start !== min || this.createEvent.end !== max) {
          this.createEvent.start = min;
          this.createEvent.end = max;
        }
      }
    },
    onTimeMouseUp() {
      if (this.dragEditable(this.dragEvent) && this.dragEvent.edited) {
        // save edits
        this.handleDragEventsMouseUp();
      } else if (this.dragEditable(this.createEvent) && !this.createEvent.added) {
        // handles have been dragged
        this.createEvent.commit();
      } else {
        if (this.dragEvent || (this.createEvent && this.createEvent.added)) {
          this.ignoreNextMenuClose = true;
          const nativeEvent = this.currentEvent;
          // select the event
          this.onEventClick({nativeEvent, event: this.dragEvent || this.createEvent});
        }
      }

      if (!this.$refs.confirmationPrompt.dialogOpen) {
        this.clearDrag();
      }
    },
    onMouseLeave() {
      if (this.sectionRoomBookingReadOnly || this.$refs.confirmationPrompt.dialogOpen) return;
      // save edits
      if (this.dragEditable(this.dragEvent)) this.dragEvent.commit();
      if (this.dragEditable(this.createEvent)) this.createEvent.commit();
      this.clearDrag();
    },

    onHandleMouseDown(anchor, event) {
      if (this.sectionRoomBookingReadOnly) return;
      if (!this.dragEditable(event)) return;
      this.createEventKey = event.key;
      this.anchorTime = event[anchor];
    },

    toTime(e) {
      return new Date(e.year, e.month - 1, e.day, e.hour, e.minute).getTime();
    },
    roundTime(t, to = 15, down = true) {
      to = to * dates.minutes;
      return down ?
          t - (t % to) :
          t + (to - (t % to));
    },

    maybeCloseMenu(e) {
      if (this.ignoreNextMenuClose) {
        this.ignoreNextMenuClose = false;
        return;
      }

      // don't close if you click on a menus content (allows nested menus)
      const menu = e.target.closest('.v-menu__content');

      // elements sometimes remove themselves from the DOM before we get the event
      // this means we can't trace it's ancestors back to know if we need to close a menu...
      // “Every weakness contains within itself a strength." - Shusaku Endo
      // use this to assume it was a sub menu click event and ignore it as an outisde click event.
      const body = e.target.closest('body');

      // don't close if you click on another event (handled via onEventClick)
      const event = e.target.closest('.v-event-timed');

      if (!event && !menu && body) {
        this.maybeResetSelected();
      }
    },
    maybeResetSelected(close = true) {
      const edited = this.selectedEvent && (this.selectedEvent.added || this.selectedEvent.editedGraph);
      if (!edited || this.resetAttemptCount >= this.resetAttemptAllowCount) {
        this.resetSelected(close);
        return true;
      } else {
        this.resetAttemptCount++;
        this.shakeMenu();
        this.scheduleResetAttemptCount();
        return false;
      }
    },
    async commitSelected(close = true) {
      this.selectedCommitting = true;
      let bookingIsBeingDeleted = false;

      try {
        if (this.selectedEvent) {
          bookingIsBeingDeleted = this.selectedEvent.deleted || false;
          await this.selectedEvent.commit();
        }

        // Check if the booking is marked for deletion so we can display an appropriate message
        if (bookingIsBeingDeleted) {
          this.$notify.showSuccess('Booking deleted');
        } else {
          // A standard edit to an existing booking, so display a saved message
          this.$notify.showSuccess('Booking saved');
        }

        if (close) {
          this.closeSelected();
        }
      } catch (e) {
        this.$logger.error('commitSelected', e);

        if (bookingIsBeingDeleted) {
          this.$notify.showError(`Unable to delete booking ` + e.message);
        } else {
          this.$notify.showError(`Unable to save booking ` + e.message);
        }
      } finally {
        this.selectedCommitting = false;
      }
    },
    resetSelected(close = true) {
      this.resetAttemptCount = 0;
      if (this.selectedEvent) this.selectedEvent.reset();
      if (close) this.closeSelected();
    },
    deleteSelected(close = true) {
      if (this.selectedEvent) {
        this.selectedEvent.delete();
        this.commitSelected(close);
      }
    },
    closeSelected() {
      this.resetAttemptCount = 0;
      this.selectedOpen = false;
      this.selectedEventKey = null;
      this.selectedElement = null;

      // clears the work order store
      this.clearWorkOrderStore();
    },
    clearDrag() {
      this.dragEventKey = null;
      this.dragTime = null;
      this.createEventKey = null;
      this.anchorTime = null;
    },
    dragEditable(event) {
      return event && this.editable && !event.blocked && !event.checkedOut;
    },
    /**
     * @param {KeyboardEvent} e
     */
    onDocumentKeyDown(e) {
      switch (e.key) {
        case 'Escape':
          if (this.selectedOpen) {
            if (!this.maybeResetSelected()) {
              // prevent the menu from closing the popup, there's no way to disable the Esc functionality
              e.stopPropagation();
            }
          }
          break;
        case 'Enter':
          if (e.ctrlKey) {
            this.commitSelected();
          }
          break;
      }
    },
    shakeMenu() {
      const menu = this.$refs.selectedMenu;
      const content = menu.$refs.content;
      if (content) {
        const doShake = () => {
          content.addEventListener('animationend', () => content.classList.remove('shake'), {once: true});
          content.classList.add('shake');
        };
        if (content.classList.contains('shake')) {
          content.classList.remove('shake');
          requestAnimationFrame(() => {
            doShake();
          });
        } else {
          doShake();
        }
      }
    },
    scheduleResetAttemptCount() {
      clearTimeout(this.resetAttemptTimeoutHandle);
      this.resetAttemptTimeoutHandle = setTimeout(() => {
        this.resetAttemptCount = 0;
      }, this.resetAttemptTimeout);
    },

    setRoomFilter(selection) {
      this.setFilterSelection(selection);
    },

    updateSiteLabelColors() {
      // set the site-label colours for each site
      const siteLabels = document.getElementsByClassName('site first-resource-for-site');
      for (let i = 0; i < siteLabels.length; i++) {
        const colorIndex = (i % 2) + 1;
        siteLabels[i].style.setProperty('--background-color', `var(--v-room-calendar-category-${colorIndex}-base)`);
      }
    },
    handleDragEventsMouseUp() {
      // check if the event being edited has any catering requests
      if (this.dragEvent.hadCateringRequests && !this.dragEvent.cateringIsEnabled) {
        // warn that the work orders will be removed.
        this.$refs.confirmationPrompt.showConfirm(
            () => {
              this.dragEvent.commit();
              this.clearDrag();
            },
            () => {
              this.dragEvent.reset();
              this.clearDrag();
            });
      } else {
        this.dragEvent.commit();
      }
    }
  }
};
</script>

<!--suppress CssUnusedSymbol -->
<style scoped>
.booking-calendar {
  overflow: auto;
  width: 100%;
  max-height: 100vh;
  --toolbar-height: 56px;
}

.booking-calendar >>> .v-calendar {
  padding-right: var(--nested-view-width);
}

/* disable overflow handling which breaks position:sticky */
.booking-calendar >>> .v-calendar,
.booking-calendar >>> .v-calendar .v-calendar-daily__body,
.booking-calendar >>> .v-calendar .v-calendar-daily__scroll-area,
.booking-calendar >>> .v-calendar .v-calendar-daily__pane {
  overflow: initial;
}

.booking-calendar >>> .v-calendar .v-event-timed-container {
  /* Disabled the right 'gutter' shown next to all events - events have more width */
  margin-right: 0;
  z-index: 1;
}

/* Hover highlighting */
.booking-calendar >>> .v-calendar .v-event-timed-container,
.booking-calendar >>> .v-calendar .v-calendar-daily__day-interval {
  /* Needed for hover events to work */
  pointer-events: initial;
}

.booking-calendar >>> .v-calendar .v-event-timed-container:hover,
.booking-calendar >>> .v-calendar .v-calendar-daily__day-interval:hover {
  background: #0001;
}

.booking-calendar >>> .v-calendar .v-calendar-daily__interval:nth-child(4n):after,
.booking-calendar >>> .v-calendar .v-calendar-daily__day-interval:nth-child(4n + 1) {
  border-top-color: #0005;
}

/*
 * Horizontal scrolling, doesn't work oob.
 * Header doesn't scroll with body.
 * Scroll bar is located at the bottom of the grid not within the viewport.
 */
.booking-calendar >>> .v-calendar .v-calendar-category__column {
  min-width: 48px;
}

.booking-calendar >>> .v-calendar .v-calendar-daily__intervals-body {
  position: sticky;
  left: 1px;
  transition: left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  background: #fffa;
  backdrop-filter: blur(3px);
  z-index: 2;
}

.booking-calendar >>> .v-event-timed.connected {
  opacity: 0.4;
}

.booking-calendar >>> .v-event-timed.checked-out {
  opacity: 0.8;
  border-style: dashed !important;
}

.booking-calendar >>> .v-event-timed.adjacent-before {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.booking-calendar >>> .v-event-timed.adjacent-after {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.booking-calendar > .v-toolbar {
  position: sticky;
  top: 0;
  left: 0;
  /* 6 is the same as the nav drawer, this means we're above the box-shadow */
  z-index: 6;
}

.booking-calendar > .loading {
  position: sticky;
  top: 56px;
  /* 6 is the same as the nav drawer, this means we're above the box-shadow */
  z-index: 6;
}

.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  background: #0001;
}

.category {
  white-space: nowrap;
  text-align: start;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  pointer-events: none;
  user-select: none;
  position: relative;
  /** above the current-time */
  z-index: 2;
  /* If there was a text-outer-outline prop, we'd use it. If text-shadow supported inset, we'd use it */
  --shadow: #FFFA;
  text-shadow: 1px 1px var(--shadow), 1px -1px var(--shadow), -1px -1px var(--shadow), -1px 1px var(--shadow);
}

.category .rotate {
  position: sticky;
  top: calc(var(--toolbar-height) + 1px);
  transform: rotate(90deg) translateX(-47px);
  transform-origin: left bottom;
  font-size: 16px;
  height: 47px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  opacity: 0.7;
}

.category .rotate.with-site {
  /** allow for site label */
  top: calc(var(--toolbar-height) + 1px + 20px);
}

.category .site {
  width: 100%;
  position: sticky;
  top: calc(var(--toolbar-height) + 1px);
  height: 20px;
  display: flex;
  justify-content: center;
  text-shadow: none;
}

.category .site span {
  display: none;
}

.category .site.first-resource-for-site {
  background-color: var(--background-color, var(--v-room-calendar-category-1-base));
  /** account for 1px border on each category */
  width: calc(100% * var(--var-site-resource-count) + 1px * (var(--var-site-resource-count) - 1));
  font-size: 14px;
  color: white;
}

.category .site.first-resource-for-site span {
  display: inline-block;
}

.fill-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.handle {
  position: absolute;
  left: 0;
  width: 100%;
  height: 8px;
  cursor: ns-resize;
}

.handle.top {
  top: -1px;
}

.handle.bottom {
  bottom: -1px;
}

.current-time {
  position: absolute;
  height: 2px;
  left: -1px;
  right: 0;
  pointer-events: none;
  z-index: 1;
  background: #A939B9;
}

.current-time:before {
  content: '';
  position: absolute;
  height: 24px;
  left: 0;
  right: 0;
  bottom: calc(100% + 1px);
  background: linear-gradient(to bottom, transparent, #0002);
}

.event-details {
  user-select: none;
}

.event-details .owner {
  max-width: 100%;
  white-space: normal;
}

.error .event-details {
  color: black;
}

.event-icon-list {
  position: absolute;
  pointer-events: none;
  left: 4px;
  bottom: 0;
  opacity: 0.7;
}

.event-icon {
  color: white;
  font-size: 18px;
  margin: 1px;
}

@keyframes shake {
  from {
    transform-origin: center center;
    transform: translateX(-4px) rotateZ(1deg);
  }
  to {
    transform-origin: center center;
    transform: translateX(4px) rotateZ(-1deg);
  }
}

.shake {
  animation: 50ms linear 0s 5 alternate shake;
}
</style>
