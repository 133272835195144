<template>
  <v-card class="booking-popout">
    <v-toolbar :class="{'expanded': showDayEditor}" flat :color="eventColor(event)" :dark="eventColorIsDark(event)">
      <v-toolbar-title class="flex-fill">
        <v-text-field
            autocomplete="off"
            class="title"
            placeholder="(No title)"
            v-model="internalName"
            hide-details
            :readonly="parsedReadOnly"
            style="min-width: 10em; max-width: initial"/>
      </v-toolbar-title>
      <v-tooltip v-if="needsApproval" bottom>
        <template #activator="{on}">
          <v-btn
              icon
              v-on="on"
              v-model="internalApproval"
              @click="internalApproval = !approved"
              :disabled="Boolean(approved || parsedReadOnly)"
              :color="approved ? 'success' : undefined">
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </template>
        {{ approved ? 'Approved' : 'Approve' }}
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{on}">
          <v-btn icon v-on="on" @click="toBookingView">
            <v-icon>mdi-arrow-expand</v-icon>
          </v-btn>
        </template>
        View more details about this booking
      </v-tooltip>
      <v-menu offset-y left min-width="260" v-if="!parsedReadOnly">
        <template #activator="{on, attrs}">
          <v-btn icon v-on="on" v-bind="attrs">
            <v-icon>mdi-dots-vertical</v-icon>
          </v-btn>
        </template>
        <v-card>
          <v-list>
            <v-list-item v-if="!hasPrepTimes" @click="addSetupTime">
              <v-list-item-icon>
                <v-icon>mdi-clock</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Add Setup Time</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="!hasNote" @click="addNote">
              <v-list-item-icon>
                <v-icon>mdi-message-text</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Add Note</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
                v-if="!internalCateringRequest && cateringIsEnabled && cateringCanBeEdited"
                @click="addCatering">
              <v-list-item-icon>
                <v-icon>mdi-coffee</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Add Catering</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider
                v-if="!isBeingAdded"/>
            <v-tooltip
                bottom
                v-if="!isBeingAdded">
              <template #activator="{ on, attrs }">
                <v-list-item
                    @click="onClick('delete')"
                    v-bind="attrs"
                    v-on="on">
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      Delete
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <div class="text-center">
                <span class="text-subtitle-2">Delete this booking</span>
                <p class="mb-0 pb-0 caption">This will remove it from all room calendars</p>
              </div>
            </v-tooltip>
          </v-list>
        </v-card>
      </v-menu>
      <template #extension>
        <v-subheader style="height: 100%" class="px-0 flex-column justify-center align-start">
          <v-expand-transition>
            <component
                :is="parsedReadOnly ? 'span' : 'a'"
                v-on="showDayEditorHandlers"
                v-if="!showDayEditor || parsedReadOnly"
                class="event-time">
              <event-time :start="internalStart" :end="internalEnd"/>
            </component>
          </v-expand-transition>
          <v-fade-transition>
            <period-input :start.sync="internalStart" :end.sync="internalEnd" v-if="!readOnly && showDayEditor"/>
          </v-fade-transition>
        </v-subheader>
      </template>
    </v-toolbar>
    <div class="grey lighten-5">
      <busy-bar
          :ranges="busyRanges"
          :min="busyRangeMin"
          :max="busyRangeMax"
          :range-color="busyRangeColor"
          dark>
        <template #range="{range: r}">
          <v-tooltip bottom>
            <template #activator="{on}">
              <div class="busy-booking" v-on="on"/>
            </template>
            <span v-if="r === event">This event</span>
            <event-time v-else short :start="r.start" :end="r.end"/>
          </v-tooltip>
        </template>
      </busy-bar>
    </div>

    <div v-if="!loaded" class="loading">
      <v-progress-circular indeterminate size="48" color="accent"/>
    </div>

    <booking-popout-tabs
        v-if="loaded"
        v-model="tab"
        v-bind.sync="event"
        :range="range"
        :read-only="parsedReadOnly"
        :has-setup="hasPrepTimes"
        :catering-is-enabled="cateringIsEnabled"
        :catering-can-be-edited="cateringCanBeEdited"
        :requires-check-in.sync="internalRequiresCheckIn"
        :show-note-override.sync="showNoteOverride"
        :show-catering-override.sync="showCateringOverride"
        :show-setup-time-override.sync="internalSetupTimeOverride"
        :catering-request.sync="internalCateringRequest"/>

    <v-card-actions>
      <v-spacer/>
      <v-btn text @click="reset" :disabled="committing">{{ resetText }}</v-btn>
      <v-fade-transition>
        <v-btn
            depressed
            v-if="showCommitBtn"
            :loading="committing"
            @click="commit"
            :color="eventColor(event) + ' white--text'">
          {{ commitText }}
        </v-btn>
      </v-fade-transition>
    </v-card-actions>
  </v-card>
</template>

<script>
import BusyBar from '@/components/BusyBar';
import {addDays, seconds, toDate, toYearMonthDay} from '@/util/dates';
import EventTime from '@/views/room-booking/calendar/EventTime';
import OwnerField from '@/views/room-booking/calendar/OwnerField';
import PeriodInput from '@/views/room-booking/calendar/PeriodInput';
import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
import eventColor from './event-color';
import {timeOfDay, compareEditDuration} from '@/util/times';
import OwnerFieldReadonly from '@/views/room-booking/calendar/OwnerFieldReadonly';
import BookingPopoutTabs from '@/views/room-booking/calendar/BookingPopoutTabs';

export default {
  name: 'BookingPopout',
  components: {BookingPopoutTabs, OwnerFieldReadonly, PeriodInput, BusyBar, OwnerField, EventTime},
  mixins: [eventColor],
  props: {
    event: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: ''
    },
    setupMins: {
      type: Number,
      default: 0
    },
    cleanDownMins: {
      type: Number,
      default: 0
    },
    cateringIsEnabled: {
      type: Boolean,
      default: false
    },
    cateringEditTimeEnd: {
      type: String,
      default: '-1d'
    },
    owner: {
      type: Object,
      default: null
    },
    note: {
      type: String,
      default: ''
    },
    approval: {
      type: Object,
      default: null
    },
    requiresCheckIn: {
      type: Boolean,
      default: true
    },
    cateringRequest: {
      type: Object,
      default: null
    },
    workOrderRefs: {
      type: Array,
      default: () => []
    },
    readOnly: Boolean,
    committing: Boolean
  },
  data() {
    return {
      tab: null,
      showSetupTimeOverride: null,
      showNoteOverride: false,
      showCateringOverride: false,
      focusNoteFieldStart: null, // for tracking how long we've been attempting to focus the note field
      maxFocusNoteFieldWait: 2 * seconds,
      showDayEditor: false,
      cancelBusyQuery: () => {
      }
    };
  },
  computed: {
    ...mapState('time', ['now']),
    ...mapGetters('sites/active', ['workingHoursStartTime', 'workingHoursEndTime']),
    isBeingAdded() {
      return this.event.added;
    },
    range() {
      return {start: this.event.bookingStart, end: this.event.bookingEnd};
    },
    parsedReadOnly() {
      return Boolean(this.event.blocked) || this.readOnly;
    },
    needsApproval() {
      return this.event.needsApproval && !this.event.connected;
    },
    approved() {
      return this.event.approved;
    },
    connectionsIn() {
      return this.event.connectionsIn || [];
    },
    hasConnectionsIn() {
      return this.connectionsIn.length > 0;
    },
    hasAdjacentTimes() {
      return this.event.hasAdjacentBefore || this.event.hasAdjacentAfter;
    },
    hasPrepTimes() {
      if (this.showSetupTimeOverride !== null) return this.showSetupTimeOverride;
      return this.hasConnectionsIn && this.hasAdjacentTimes;
    },
    hasNote() {
      return this.showNoteOverride || Boolean(this.event.note);
    },
    otherLocations() {
      return this.event.reservationCount - 1;
    },
    showNote() {
      return this.showNoteOverride && !this.readOnly;
    },
    showCatering() {
      return this.showCateringOverride && !this.readOnly;
    },
    cateringCanBeEdited() {
      return compareEditDuration(this.cateringEditTimeEnd, this.event.start, this.now);
    },
    loaded() {
      return this.workOrdersLoaded;
    },
    ...mapGetters('views/roomBooking/bookingCalendar', ['eventsByCategory', 'resourceByTitle']),
    ...mapGetters('views/roomBooking/bookingCalendar/workOrders', {
      workOrderByRef: 'workOrderByRef',
      workOrdersByCategory: 'workOrdersByCategory',
      hasEdits: 'hasEdits',
      workOrdersLoaded: 'loaded'
    }),
    busyQuery() {
      /** @type {CalendarEvent} */
      const event = this.event;
      if (!event) return null;

      const t0 = event.start;
      const startDate = toDate(t0);
      const start = {date: toYearMonthDay(startDate)};
      const end = {date: toYearMonthDay(addDays(startDate, 1))};
      const resource = this.resourceByTitle(event.category);

      return {
        timePeriod: {start, end},
        resources: [resource]
      };
    },
    busyRanges() {
      if (!this.event) return [];
      return this.eventsByCategory(this.event.category);
    },
    busyRangeMin() {
      return timeOfDay(toDate(this.event.start), this.workingHoursStartTime).getTime();
    },
    busyRangeMax() {
      return timeOfDay(toDate(this.event.end), this.workingHoursEndTime).getTime();
    },

    showDayEditorHandlers() {
      if (this.parsedReadOnly) return {};
      return {
        click: () => {
          this.showDayEditor = true;
        }
      };
    },

    eventHasChanges() {
      return this.event.added || this.event.editedGraph || this.hasEdits;
    },
    showCommitBtn() {
      return this.eventHasChanges;
    },
    commitText() {
      if (this.event.added) return 'Create';
      if (this.event.editedGraph || this.hasEdits) return 'Save';
      return 'Unchanged';
    },
    resetText() {
      return this.eventHasChanges ? 'Discard' : 'Close';
    },

    internalName: {
      get() {
        return this.name;
      },
      set(v) {
        this.$emit('update:name', v);
      }
    },
    internalSetupMins: {
      get() {
        return this.setupMins;
      },
      set(v) {
        this.$emit('update:setupMins', v);
      }
    },
    internalCleanDownMins: {
      get() {
        return this.cleanDownMins;
      },
      set(v) {
        this.$emit('update:cleanDownMins', v);
      }
    },
    internalOwner: {
      get() {
        return this.owner;
      },
      set(v) {
        this.$emit('update:owner', v);
      }
    },
    internalOwnerRef() {
      return this.owner && this.owner.ref;
    },
    internalStart: {
      get() {
        return this.event.start;
      },
      set(v) {
        this.$emit('update:start', v);
      }
    },
    internalEnd: {
      get() {
        return this.event.end;
      },
      set(v) {
        this.$emit('update:end', v);
      }
    },
    internalNote: {
      get() {
        return this.note || '';
      },
      set(v) {
        this.$emit('update:note', v);
      }
    },
    internalCateringRequest: {
      get() {
        return this.cateringRequest;
      },
      set(v) {
        if (this.showCateringOverride && !v.value) {
          // no catering request to remove, this is entirely a local change
          this.showCateringOverride = false;
        }
        this.$emit('update:catering-request', v);
      }
    },
    internalApproval: {
      get() {
        return this.approval;
      },
      set(v) {
        this.$emit('update:approval', v);
      }
    },
    internalSetupTimeOverride: {
      get() {
        return this.showSetupTimeOverride;
      },
      set(v) {
        this.showSetupTimeOverride = v;
      }
    },
    internalRequiresCheckIn: {
      get() {
        return this.requiresCheckIn;
      },
      set(v) {
        this.$emit('update:requiresCheckIn', v);
      }
    }
  },
  watch: {
    event() {
      this.showSetupTimeOverride = null;
      this.showNoteOverride = null;
      this.showCateringOverride = null;
    },
    workOrderRefs: {
      immediate: true,
      handler(v) {
        this.setWorkOrderRefs(v || []);
      }
    },
    hasAdjacentTimes: {
      immediate: true,
      handler(b) {
        // if the event already has setup time, force show it
        if (b) {
          this.showSetupTimeOverride = true;
        }
      }
    },
    internalNote: {
      immediate: true,
      handler(b) {
        // if the event already has a note, force show it even if it then gets cleared
        if (b) {
          this.showNoteOverride = true;
        }
      }
    },
    busyQuery: {
      immediate: true,
      handler(n, o) {
        if (n === o) return;
        if (n && o &&
            n.timePeriod && o.timePeriod &&
            n.timePeriod.start && o.timePeriod.start && n.timePeriod.end && o.timePeriod.end &&
            n.timePeriod.start.date === o.timePeriod.start.date && n.timePeriod.end.date === o.timePeriod.end.date &&
            n.resources && n.resources.length === 1 && o.resources && o.resources.length === 1 &&
            n.resources[0].ref.isEqual(o.resources[0].ref)) return;
        this.cancelQueries();
        this.watchBookings(n)
            .then(cancel => this.cancelBusyQuery = cancel);
      }
    }
  },
  beforeDestroy() {
    this.cancelQueries();
  },
  methods: {
    ...mapActions('views/roomBooking/bookingCalendar', ['copyEditsToBookingStore', 'watchBookings']),
    ...mapMutations('views/roomBooking/bookingCalendar/workOrders', ['setWorkOrderRefs']),
    toBookingView() {
      // copy any edits from our store to the /booking store
      this.copyEditsToBookingStore(this.event.id);
      this.$router.push({name: 'room-booking-calendar-view', params: {id: this.event.id}});
      this.$emit('close');
    },
    onClick(name, payload) {
      const eventName = `click:${name}`;
      if (this.$listeners[eventName]) {
        this.$emit(eventName, payload || this.event);
      }
    },
    addSetupTime() {
      this.internalSetupMins = 15;
      this.internalCleanDownMins = 15;
      this.goToTab('Setup');
    },
    addCatering() {
      this.showCateringOverride = true;
      this.goToTab('Catering');
    },
    addNote() {
      this.showNoteOverride = true;
      this.goToTab('Note');
    },
    goToTab(t) {
      // we need to wait for the tab to exist before switching
      this.$nextTick(() => this.tab = t);
    },
    busyRangeColor(range) {
      return range === this.event ? this.eventColor(this.event) : 'grey lighten-4';
    },
    cancelQueries() {
      const fn = this.cancelBusyQuery;
      this.cancelBusyQuery = () => {
      };
      fn();
    },
    focusNoteField() {
      if (!this.$refs.noteField) {
        if (this.focusNoteFieldStart !== null) {
          // not the first time we've tried, check if we should give up
          if (Date.now() - this.focusNoteFieldStart > this.maxFocusNoteFieldWait) {
            this.focusNoteFieldStart = null;
            return; // give up
          }
        } else {
          this.focusNoteFieldStart = Date.now();
        }
        setTimeout(() => {
          this.focusNoteField();
        }, 10);
      } else {
        this.focusNoteFieldStart = null;
        this.$refs.noteField.focus();
      }
    },

    commit() {
      this.$emit('click:commit');
    },
    reset() {
      this.$emit('click:reset');
    },
    removeSetupTimes() {
      this.internalSetupMins = 0;
      this.internalCleanDownMins = 0;
      this.showSetupTimeOverride = null;
    }
  }
};
</script>

<style scoped>

.expanded {
  height: 154px!important;
}

.expanded .v-toolbar__extension {
  height: 80px!important;
}

.booking-popout >>> .v-toolbar__title {
  overflow: initial;
}

.booking-popout >>> .striped {
  /* helps with striped busy bars aligning stripes with the toolbar */
  background-attachment: fixed;
}

.booking-popout .event-time {
  color: white;
}
.booking-popout .theme--light .event-time {
  color: black;
}

.title:not(:hover) >>> .v-input__slot:before {
  opacity: 0;
}

.field-name {
  font-size: 16px;
  display: flex;
}

.field-name span:first-child {
  padding-left: 32px;
}

.info-table {
  display: grid;
  grid-gap: 8px 16px;
  grid-template-columns: auto 1fr;
  grid-auto-rows: auto 1fr;
  font-size: 16px;
}

.info-table dt {
  grid-column-start: 1;
}

.info-table dd {
  grid-column-start: 2;
}

.setup-times {
  font-size: 16px;
  display: grid;
  grid-template-columns: min-content repeat(2, 8em) auto;
  grid-gap: 8px;
}

.busy-booking {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.notes-field >>> textarea {
  overflow: auto;
  max-height: 15em;
  margin-top: 0 !important;
  padding-top: 10px;
  padding-bottom: 10px;
}

.loading {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
